// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vue-async-computed'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import * as firebase from 'firebase'

Vue.config.productionTip = false

const configOptions = {
  apiKey: 'AIzaSyCS5eQuY0ULz90vmtR8qwborNSfu4YSjmA',
  authDomain: 'letsconnectauth.firebaseapp.com',
  projectId: 'letsconnectauth',
  storageBucket: 'letsconnectauth.appspot.com',
  messagingSenderId: '648661927102',
  appId: '1:648661927102:web:fdcd60577d49a45da24aef',
}

firebase.default.initializeApp(configOptions)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
