import Vue from 'vue'
import Router from 'vue-router'
// import AuthService from '@/services/AuthService'
// import axios from 'axios'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/pages',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        isSecure: true,
        isDashboardRoot: true,
      },
      children: [
        // Pages
        {
          name: 'My Senders',
          path: 'senders',
          component: () => import('@/views/dashboard/pages/MySenders'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Addons',
          path: 'addons',
          component: () => import('@/views/dashboard/pages/AppAddonsPage'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'My Contacts',
          path: 'contacts',
          component: () => import('@/views/dashboard/pages/MyContacts'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'My Groups',
          path: 'groups',
          component: () => import('@/views/dashboard/pages/MyGroups'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Group Contacts',
          path: 'groups/:groupId/contacts',
          props: true,
          component: () => import('@/views/dashboard/pages/GroupContacts'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Message History',
          path: 'history*',
          props: true,
          component: () => import('@/views/dashboard/pages/MessageHistory'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Contact Profile',
          path: 'contact-profile/:id?',
          props: true,
          component: () => import('@/views/dashboard/pages/ContactProfile'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Google Review',
          path: 'google-review',
          props: true,
          component: () => import('@/views/dashboard/pages/GoogleReviews'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Message Insights',
          path: 'insights',
          props: true,
          component: () => import('@/views/dashboard/pages/MessageInsights'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Survey Insights',
          path: 'survey-insights',
          props: true,
          component: () => import('@/views/dashboard/pages/SurveyInsights'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Manage Templates',
          path: 'manage-templates',
          props: true,
          component: () => import('@/views/dashboard/pages/ManageTemplates'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Dashboard',
          path: 'dashboard',
          props: true,
          component: () => import('@/views/dashboard/pages/DashboardView'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'My Profile',
          path: 'user',
          component: () => import('@/views/dashboard/pages/MyProfile'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Transactions',
          path: 'transactions',
          component: () => import('@/views/dashboard/pages/TransactionsPage'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Twilio Credentials',
          path: 'twilio-creds',
          component: () => import('@/views/dashboard/pages/TwilioCreds'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Video Meet Settings',
          path: 'video-meet-settings',
          component: () => import('@/views/dashboard/pages/VideoMeetSettings'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Payment Plans',
          path: 'payment-plans',
          component: () => import('@/views/dashboard/pages/PaymentPlans'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Knowledge Base',
          path: 'knowledgebase',
          component: () => import('@/views/dashboard/pages/FAQ'),
          meta: {
            isSecure: true,
          },
        },
        {
          name: 'Scheduled SMS',
          path: 'scheduled-sms',
          component: () => import('@/views/dashboard/pages/ScheduledSms'),
          meta: {
            isSecure: true,
          },
        },
      ],
    }, {
      path: '/home',
      name: 'Home',
      component: () => import('@/views/landing/Index'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/about-us',
      name: 'AboutUs',
      component: () => import('@/views/dashboard/pages/AboutUsPage'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/contact-us',
      name: 'ContactUs',
      component: () => import('@/views/dashboard/pages/ContactUsPage'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/plans',
      name: 'Plans',
      component: () => import('@/views/dashboard/pages/PlansPage'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/services',
      name: 'Services',
      component: () => import('@/views/dashboard/pages/ServicesPage'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/auth/LoginPage'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/register',
      name: 'Register',
      component: () => import('@/views/auth/RegisterPage'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/no-access',
      name: 'Verify Email',
      component: () => import('@/views/dashboard/pages/VerifyEmailNotifierPage'),
      meta: {
        isSecure: false,
      },
      props: {
        userEmail: '',
      },
    }, {
      path: '/forget-password',
      name: 'Forget Password',
      component: () => import('@/views/auth/ForgetPassword'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/auth/linkedin',
      component: () => import('@/views/auth/LinkedIn'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/sms-service',
      name: 'SMS Service',
      component: () => import('@/views/dashboard/pages/SMSServicePage'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/mms-service',
      name: 'MMS Service',
      component: () => import('@/views/dashboard/pages/MMSServicePage'),
      meta: {
        isSecure: false,
      },
    }, {
      path: '/market-sales',
      name: 'MarketingSales',
      component: () => import('@/views/dashboard/pages/MarketingSales'),
      meta: {
        isSecure: false,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    next({ name: 'Home' })
  } else {
    if (to.matched.some(record => record.meta.isSecure)) {
      // Secured route
      const username = localStorage.getItem('username')
      if (username && username.length) {
        if (to.path === '/pages') {
          next({ name: 'Dashboard' })
        } else {
          next()
        }
      } else {
        next({ name: 'Login' })
      }
    } else {
      // Not secured route
      const username = localStorage.getItem('username')
      if (username && username.length && (to.path.indexOf('auth') > -1 || to.path.indexOf('login') > -1)) {
        // Logged in
        // But Trying to navigate to login / auth pages
        // SO redirect to dashboard
        next({ name: 'Dashboard' })
      } else {
        next()
      }
    }
  }
})

export default router
