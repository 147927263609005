<template>
  <div>
    <router-view />
    <messaging-popup />
    <contact-profile-popup />
  </div>
</template>

<script>
  // import router from './router'

  export default {
    name: 'App',
    components: {
      MessagingPopup: () => import('./views/dashboard/components/MessagingPopup.vue'),
      ContactProfilePopup: () => import('./views/dashboard/components/general/contact-profile-popup.vue'),
    },
    mounted () {
      // const { pathname } = window.location
      // const hasParts = pathname.split('/')
      // if (!hasParts || hasParts.length < 2 || !(hasParts[1] && hasParts[1].length)) {
      //   router.push('/pages/contacts')
      // }
    },
  }
</script>
